define("discourse/plugins/discourse-encrypt/lib/uppy-upload-encrypt-plugin", ["exports", "rsvp", "discourse/lib/uppy-plugin-base", "discourse/mixins/uppy-upload", "discourse/plugins/discourse-encrypt/lib/base64", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/uploads"], function (_exports, _rsvp, _uppyPluginBase, _uppyUpload, _base, _discourse, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class UppyUploadEncrypt extends _uppyPluginBase.UploadPreProcessorPlugin {
    constructor(uppy, opts) {
      super(uppy, opts);
      this.composerModel = opts.composerModel;
      this.storeEncryptedUpload = opts.storeEncryptedUpload;
      this.siteSettings = opts.siteSettings;
    }
    async _encryptFile(fileId) {
      const file = this._getFile(fileId);
      if (file.size > _uppyUpload.HUGE_FILE_THRESHOLD_BYTES) {
        return this._emitError(file, "The provided file is too large to upload to an encrypted message.");
      }
      this._emitProgress(file);
      const key = await (0, _uploads.generateUploadKey)();
      let exportedKey = await window.crypto.subtle.exportKey("raw", key);
      exportedKey = (0, _base.bufferToBase64)(exportedKey);
      const metadata = await (0, _uploads.getMetadata)(file.data, this.siteSettings);
      const plaintext = await (0, _uploads.readFile)(file.data);
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const ciphertext = await window.crypto.subtle.encrypt({
        name: "AES-GCM",
        iv,
        tagLength: 128
      }, key, plaintext);
      const blob = new Blob([iv, ciphertext], {
        type: "application/x-binary"
      });
      this._setFileState(fileId, {
        data: blob,
        size: blob.size,
        name: `${file.name}.encrypted`
      });
      this._setFileMeta(fileId, {
        name: `${file.name}.encrypted`
      });
      this.storeEncryptedUpload(file.name, {
        key: exportedKey,
        metadata,
        type: file.type,
        filesize: blob.size
      });
      this._emitComplete(file);
    }
    async _encryptFiles(fileIds) {
      if (!this.composerModel.isEncrypted && !(0, _discourse.hasTopicKey)(this.composerModel.get("topic.id"))) {
        this._consoleDebug("Composer is not being used in an encrypted context, skipping all files.");
        return this._skipAll(fileIds, true);
      }
      const encryptPromises = fileIds.map(fileId => this._encryptFile(fileId));
      return _rsvp.Promise.all(encryptPromises);
    }
    install() {
      this._install(this._encryptFiles.bind(this));
    }
    uninstall() {
      this._uninstall(this._encryptFiles.bind(this));
    }
  }
  _exports.default = UppyUploadEncrypt;
  _defineProperty(UppyUploadEncrypt, "pluginId", "uppy-upload-encrypt");
});