define("discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/paper-key", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _ajax, _decorators, _discourse, _paperKey, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.generate}}
    @closeModal={{@closeModal}}
    @title={{i18n
      (if
        @model.device
        "encrypt.generate_paper_key.title_device"
        "encrypt.generate_paper_key.title"
      )
    }}
  >
    <:body>
      <p>
        {{i18n
          (if
            @model.device
            "encrypt.generate_paper_key.instructions_device"
            "encrypt.generate_paper_key.instructions"
          )
        }}
      </p>
      <p class="paper-key">{{this.paperKey}}</p>
    </:body>
  </DModal>
  */
  {
    "id": "24vE7bjf",
    "block": "[[[8,[39,0],[[4,[38,3],[[30,0,[\"generate\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.title_device\",\"encrypt.generate_paper_key.title\"]],null]]],[[\"body\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[[52,[30,2,[\"device\"]],\"encrypt.generate_paper_key.instructions_device\",\"encrypt.generate_paper_key.instructions\"]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"paper-key\"],[12],[1,[30,0,[\"paperKey\"]]],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"if\",\"did-insert\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/generate-paper-key.hbs",
    "isStrictMode": false
  });
  let GeneratePaperKey = _exports.default = (_class = class GeneratePaperKey extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "paperKey", _descriptor, this);
    }
    async generate() {
      const paperKey = (0, _paperKey.generatePaperKey)();
      const label = this.args.model.device ? "device" : `paper_${paperKey.substr(0, paperKey.indexOf(" ")).toLowerCase()}`;
      const identity = await (0, _discourse.getIdentity)();
      const exported = await (0, _protocol.exportIdentity)(identity, paperKey);
      this.paperKey = paperKey;
      await (0, _ajax.ajax)("/encrypt/keys", {
        type: "PUT",
        data: {
          public: exported.public,
          private: exported.private,
          label
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paperKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generate", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeneratePaperKey);
});