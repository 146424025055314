define("discourse/plugins/discourse-encrypt/discourse/components/modal/export-key-pair", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "discourse/lib/copy-text", "discourse-common/utils/decorators", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/pack", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _copyText, _decorators, _discourse, _pack, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.export}}
    @closeModal={{@closeModal}}
    @title={{i18n "encrypt.export.title"}}
  >
    <:body>
      <p>{{i18n "encrypt.export.instructions"}}</p>
      <pre class="exported-key-pair">{{this.exported}}</pre>
    </:body>
  
    <:footer>
      <DButton
        @action={{this.copy}}
        @icon="far-clipboard"
        @label={{if
          this.copied
          "encrypt.export.copied_to_clipboard"
          "encrypt.export.copy_to_clipboard"
        }}
        @disabled={{this.inProgress}}
        class={{concat-class (if this.copied "ok") "btn btn-primary"}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "ql+IBRfV",
    "block": "[[[8,[39,0],[[4,[38,2],[[30,0,[\"export\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"encrypt.export.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.export.instructions\"],null]],[13],[1,\"\\n    \"],[10,\"pre\"],[14,0,\"exported-key-pair\"],[12],[1,[30,0,[\"exported\"]]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[16,0,[28,[37,4],[[52,[30,0,[\"copied\"]],\"ok\"],\"btn btn-primary\"],null]]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"copy\"]],\"far-clipboard\",[52,[30,0,[\"copied\"]],\"encrypt.export.copied_to_clipboard\",\"encrypt.export.copy_to_clipboard\"],[30,0,[\"inProgress\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"d-button\",\"concat-class\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/export-key-pair.hbs",
    "isStrictMode": false
  });
  let ExportKeyPair = _exports.default = (_class = class ExportKeyPair extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "inProgress", _descriptor, this);
      _initializerDefineProperty(this, "exported", _descriptor2, this);
      _initializerDefineProperty(this, "copied", _descriptor3, this);
    }
    async export() {
      try {
        const identity = await (0, _discourse.getIdentity)();
        const exported = await (0, _protocol.exportIdentity)(identity);
        this.exported = (0, _pack.packIdentity)(exported.private);
        this.inProgress = false;
      } catch {
        this.inProgress = false;
      }
    }
    copy() {
      const copyRange = document.querySelector("pre.exported-key-pair");
      if ((0, _copyText.default)("", copyRange)) {
        this.copied = true;
        (0, _runloop.later)(() => this.copied = false, 2000);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "exported", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "copied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "export", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "export"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExportKeyPair);
});