define("discourse/plugins/discourse-encrypt/discourse/components/modal/reset-key-pair", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-encrypt/lib/database", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _database, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.loadStats}}
    @title={{i18n "encrypt.reset.title"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <ConditionalLoadingSection @isLoading={{this.isLoadingStats}}>
        {{#if this.encryptedPmsCount}}
          <p>{{i18n "encrypt.reset.instructions"}}</p>
          <p>
            {{html-safe
              (i18n
                "encrypt.reset.instructions_lost_pms" count=this.encryptedPmsCount
              )
            }}
            {{html-safe
              (i18n
                "encrypt.reset.confirm_instructions"
                username=this.currentUser.username
              )
            }}
          </p>
          <Input @type="text" @value={{this.confirmation}} />
        {{else}}
          <p>{{i18n "encrypt.reset.instructions_safe"}}</p>
        {{/if}}
      </ConditionalLoadingSection>
    </:body>
  
    <:footer>
      <DButton
        class="btn btn-danger"
        @icon="trash-alt"
        @label="encrypt.reset.title"
        @action={{this.reset}}
        @disabled={{this.disabled}}
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "hHaMQxdp",
    "block": "[[[8,[39,0],[[4,[38,2],[[30,0,[\"loadStats\"]]],null]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"encrypt.reset.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@isLoading\"],[[30,0,[\"isLoadingStats\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"encryptedPmsCount\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.reset.instructions\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,1],[\"encrypt.reset.instructions_lost_pms\"],[[\"count\"],[[30,0,[\"encryptedPmsCount\"]]]]]],null]],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,1],[\"encrypt.reset.confirm_instructions\"],[[\"username\"],[[30,0,[\"currentUser\",\"username\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,6],null,[[\"@type\",\"@value\"],[\"text\",[30,0,[\"confirmation\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.reset.instructions_safe\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn btn-danger\"]],[[\"@icon\",\"@label\",\"@action\",\"@disabled\"],[\"trash-alt\",\"encrypt.reset.title\",[30,0,[\"reset\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n    \"],[8,[39,8],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"conditional-loading-section\",\"if\",\"html-safe\",\"input\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/reset-key-pair.hbs",
    "isStrictMode": false
  });
  let ResetKeyPair = _exports.default = (_class = class ResetKeyPair extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "isLoadingStats", _descriptor3, this);
      _initializerDefineProperty(this, "inProgress", _descriptor4, this);
      _initializerDefineProperty(this, "encryptedPmsCount", _descriptor5, this);
      _initializerDefineProperty(this, "confirmation", _descriptor6, this);
    }
    get disabled() {
      return this.isLoadingStats || this.inProgress || this.encryptedPmsCount > 0 && this.currentUser.username !== this.confirmation;
    }
    async loadStats() {
      try {
        const result = await (0, _ajax.ajax)("/encrypt/stats", {
          data: {
            user_id: this.args.model.user.id
          }
        });
        if (result.encrypted_pms_count > 0) {
          this.encryptedPmsCount = result.encrypted_pms_count;
        }
      } finally {
        this.isLoadingStats = false;
      }
    }
    async reset() {
      this.inProgress = true;
      try {
        // eslint-disable-next-line no-restricted-globals
        await Promise.all([(0, _ajax.ajax)("/encrypt/reset", {
          type: "POST",
          data: {
            user_id: this.args.model.user.id
          }
        }), _database.deleteDb]);
        this.currentUser.setProperties({
          encrypt_public: null,
          encrypt_private: null
        });
        this.appEvents.trigger("encrypt:status-changed");
        this.args.closeModal();
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.inProgress = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoadingStats", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "encryptedPmsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadStats", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ResetKeyPair);
});