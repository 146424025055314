define("discourse/plugins/discourse-encrypt/discourse/components/modal/rotate-key-pair", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-encrypt/lib/database", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _database, _discourse, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "encrypt.rotate.title"}}
    @closeModal={{@closeModal}}
    @flash={{this.error}}
    @flashType="error"
  >
    <:body>
      <p>{{i18n "encrypt.rotate.instructions"}}</p>
      <p>
        {{html-safe
          (i18n
            "encrypt.reset.confirm_instructions"
            username=this.currentUser.username
          )
        }}
      </p>
      <Input @type="text" @value={{this.confirmation}} />
    </:body>
  
    <:footer>
      <DButton
        class="btn btn-primary"
        @icon="sync"
        @label={{this.label}}
        @action={{this.rotate}}
        @disabled={{this.disabled}}
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "BqVgNq9f",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[37,1],[\"encrypt.rotate.title\"],null],[30,1],[30,0,[\"error\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.rotate.instructions\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,1],[\"encrypt.reset.confirm_instructions\"],[[\"username\"],[[30,0,[\"currentUser\",\"username\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@value\"],[\"text\",[30,0,[\"confirmation\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn btn-primary\"]],[[\"@icon\",\"@label\",\"@action\",\"@disabled\"],[\"sync\",[30,0,[\"label\"]],[30,0,[\"rotate\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"input\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/rotate-key-pair.hbs",
    "isStrictMode": false
  });
  let RotateKeyPair = _exports.default = (_class = class RotateKeyPair extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "confirmation", _descriptor3, this);
      _initializerDefineProperty(this, "loadingState", _descriptor4, this);
      _initializerDefineProperty(this, "error", _descriptor5, this);
    }
    get label() {
      return this.loadingState ? `encrypt.rotate.loading_states.${this.loadingState}` : "encrypt.rotate.title";
    }
    get disabled() {
      return this.loadingState || this.currentUser.username !== this.confirmation;
    }
    async rotate() {
      this.loadingState = "fetching";
      this.error = null;
      try {
        // eslint-disable-next-line no-restricted-globals
        const [data, oldIdentity, newIdentity] = await Promise.all([(0, _ajax.ajax)("/encrypt/rotate"), (0, _discourse.getIdentity)(), (0, _protocol.generateIdentity)()]);
        this.loadingState = "rotating";

        // Don't rotate signatures because that will invalidate all previous
        // signatures.
        // When the old identity is v0, there's no keypair for signing, so don't
        // overwrite the new identity's signing keypair with nothing (undefined)
        if (oldIdentity.signPublic && oldIdentity.signPrivate) {
          newIdentity.signPublic = oldIdentity.signPublic;
          newIdentity.signPrivate = oldIdentity.signPrivate;
        }
        const topicKeys = {};
        // eslint-disable-next-line no-restricted-globals
        await Promise.all(Object.entries(data.topic_keys).map(async _ref => {
          let [topicId, topicKey] = _ref;
          const key = await (0, _protocol.importKey)(topicKey, oldIdentity.encryptPrivate);
          topicKeys[topicId] = await (0, _protocol.exportKey)(key, newIdentity.encryptPublic);
        }));
        const exportedIdentity = await (0, _protocol.exportIdentity)(newIdentity);
        this.loadingState = "saving";
        await (0, _ajax.ajax)("/encrypt/rotate", {
          type: "PUT",
          data: {
            public: exportedIdentity.public,
            keys: topicKeys
          }
        });
        this.loadingState = "updating";
        await (0, _database.saveDbIdentity)(newIdentity);
        this.loadingState = "finished";
        this.appEvents.trigger("encrypt:status-changed");
      } catch (error) {
        this.confirmation = "";
        this.loadingState = null;
        this.error = (0, _ajaxError.extractError)(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "confirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loadingState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "rotate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "rotate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RotateKeyPair);
});